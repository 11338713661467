import axiosRequest from "@/utils/axiosRequest";

export function detail(data) {
  return axiosRequest({
    url: `/account/detail`,
    data,
    method: "post"
  });
}

export function update(data) {
  return axiosRequest({
    url: `/kcon/update/user`,
    data,
    method: "post"
  });
}

export function updatePassword(data) {
    return axiosRequest({
        url: `/account/updatePassword`,
        data,
        method: 'post'
    })
  }

  export function login(data) {
    return axiosRequest({
        url: `/kcon/login`,
        data,
        method: 'post'
    })
  }
  
  export function getUserInfo(data) {
    return axiosRequest({
        url: `/kcon/get/user`,
        data,
        method: 'post'
    })
  }

  export function forgetPassword(data) {
    return axiosRequest({
        url: `/account/resetPassword`,
        data,
        method: 'post'
    })
  }

  export function updateMail(data) {
    return axiosRequest({
        url: `/kcon/update/mail`,
        data,
        method: 'post'
    })
  }

  export function deleteMail(data) {
    return axiosRequest({
        url: `/kcon/del/mail`,
        data,
        method: 'post'
    })
  }

  export function register(data) {
    return axiosRequest({
        url: `/kcon/register/user`,
        data,
        method: 'post'
    })
  }

  export function resetPWD(data) {
    return axiosRequest({
        url: `/kcon/reset/pwd`,
        data,
        method: 'post'
    })
  }